import React from 'react';
import { array, bool, func, shape, string, number, oneOfType, arrayOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import { propTypes } from '../../util/types';
import { withViewport } from '../../util/contextHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureListing, ensureUser, userDisplayNameAsString } from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { addReviewDislike, addReviewLike, rateShow, sendReview } from './ReviewsPage.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SectionRecentlyViewedListings,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';

// Sections
import SectionHero from './SectionHero';
import SectionReviews from './SectionReviews';

import css from './ReviewsPage.module.css';
import SectionSimilarShows from './SectionSimilarShows';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const prepareSimilarShows = (listings, listingId) =>
  listings.filter(l => l.id.uuid !== listingId).slice(0, 4);

const ReviewsPageComponent = props => {
  const {
    intl,
    history,
    location,
    params: rawParams,
    currentUserReactions,
    // UI
    scrollingDisabled,
    onManageDisableScrolling,
    // Listing
    getListing,
    showListingError,
    // user(s)
    currentUser,
    // reviews
    reviews,
    addReviewLikeInProgress,
    addReviewDislikeInProgress,
    addReviewLikeError,
    addReviewDislikeError,
    sendReviewInProgress,
    sendReviewError,
    onSendReview,
    onAddReviewLike,
    onAddReviewDislike,
    // listings
    similarShows,
    querySimilarShowsInProgress,
    querySimilarShowsError,
    recentlyViewedListings,
    queryRecentlyViewedListingsInProgress,
    queryRecentlyViewedListingsError,
  } = props;

  const listingId = new UUID(rawParams.id);
  const currentListing = ensureListing(getListing(listingId));

  const { description = '', title = '', publicData } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const topbar = (
    <TopbarContainer desktopClassName={css.topbarDesktop} contentClassName={css.topbarContent} />
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    const errorTitle = intl.formatMessage({
      id: 'ReviewsPage.errorLoadingListingTitle',
    });

    return (
      <Page title={errorTitle} scrollingDisabled={scrollingDisabled} currentPage="ReviewsPage">
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.errorText}>
              <FormattedMessage id="ReviewsPage.errorLoadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer currentPage="ReviewsPage" />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  } else if (!currentListing.id) {
    // Still loading the listing

    const loadingTitle = intl.formatMessage({
      id: 'ReviewsPage.loadingListingTitle',
    });

    return (
      <Page title={loadingTitle} scrollingDisabled={scrollingDisabled} currentPage="ReviewsPage">
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.loadingText}>
              <FormattedMessage id="ReviewsPage.loadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer currentPage="ReviewsPage" />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const listingImages = (listing, variantName) =>
    (listing.images || [])
      .map(image => {
        const variants = image.attributes.variants;
        const variant = variants ? variants[variantName] : null;

        // deprecated
        // for backwards combatility only
        const sizes = image.attributes.sizes;
        const size = sizes ? sizes.find(i => i.name === variantName) : null;

        return variant || size;
      })
      .filter(variant => variant != null);

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'ReviewsPage.schemaTitle' }, { title, siteTitle });

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      currentPage="ReviewsPage"
      contentType="website"
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description: description,
        name: schemaTitle,
        image: schemaImages,
      }}
    >
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar className={css.pageTopbar}>{topbar}</LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div>
            <SectionHero title={title} richTitle={richTitle} listingId={listingId} />
            <div className={css.contentContainer}>
              <SectionReviews
                intl={intl}
                location={location}
                history={history}
                listingId={listingId}
                reviews={reviews}
                currentUser={currentUser}
                currentUserReactions={currentUserReactions}
                listingTitle={title}
                isOwnListing={isOwnListing}
                sendReviewInProgress={sendReviewInProgress}
                sendReviewError={sendReviewError}
                addReviewLikeInProgress={addReviewLikeInProgress}
                addReviewLikeError={addReviewLikeError}
                addReviewDislikeInProgress={addReviewDislikeInProgress}
                addReviewDislikeError={addReviewDislikeError}
                onSendReview={review => onSendReview(listingId, review)}
                onAddReviewLike={reviewId => onAddReviewLike(listingId, reviewId)}
                onAddReviewDislike={reviewId => onAddReviewDislike(listingId, reviewId)}
                onManageDisableScrolling={onManageDisableScrolling}
              />
              <div className={css.asideContent}>
                <SectionSimilarShows
                  similarShows={prepareSimilarShows(similarShows, listingId.uuid)}
                  querySimilarShowsInProgress={querySimilarShowsInProgress}
                  querySimilarShowsError={querySimilarShowsError}
                />
              </div>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <SectionRecentlyViewedListings
            intl={intl}
            recentlyViewedListings={recentlyViewedListings}
            queryRecentlyViewedListingsInProgress={queryRecentlyViewedListingsInProgress}
            queryRecentlyViewedListingsError={queryRecentlyViewedListingsError}
            isShowPage
          />
          <Footer currentPage="ReviewsPage" />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ReviewsPageComponent.defaultProps = {
  // listing
  showListingInProgress: false,
  showListingError: null,

  // user(s)
  isAuthenticated: false,
  currentUser: null,

  // reviews
  sendReviewInProgress: false,
  sendReviewError: null,
  addReviewLikeInProgress: false,
  addReviewLikeError: null,
  addReviewDislikeInProgress: false,
  addReviewDislikeError: null,

  // show(s)
  querySimilarShowsInProgress: false,
  querySimilarShowsError: null,
  recentlyViewedListings: [],
  queryRecentlyViewedListingsInProgress: false,
  queryRecentlyViewedListingsError: null,

  filterConfig: config.custom.filters,
};

ReviewsPageComponent.propTypes = {
  // UI
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,

  // listing
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  showListingError: propTypes.error,

  // reviews
  reviews: array.isRequired,
  onSendReview: func.isRequired,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onAddReviewLike: func.isRequired,
  addReviewLikeInProgress: bool.isRequired,
  addReviewLikeError: propTypes.error,
  onAddReviewDislike: func.isRequired,
  addReviewDislikeInProgress: bool.isRequired,
  addReviewDislikeError: propTypes.error,

  // user(s)
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,

  // show(s)
  similarShows: arrayOf(propTypes.listing),
  querySimilarShowsInProgress: bool.isRequired,
  querySimilarShowsError: propTypes.error,
  recentlyViewedListings: arrayOf(propTypes.listing),
  queryRecentlyViewedListingsInProgress: bool.isRequired,
  queryRecentlyViewedListingsError: propTypes.error,

  params: shape({
    id: string.isRequired,
  }).isRequired,
  filterConfig: array,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    showListingInProgress,
    reviews,
    fetchReviewsInProgress,
    fetchReviewsError,
    sendReviewInProgress,
    sendReviewError,
    addReviewLikeInProgress,
    addReviewLikeError,
    addReviewDislikeInProgress,
    addReviewDislikeError,
  } = state.ReviewsPage;
  const { similarShowIds, querySimilarShowsInProgress, querySimilarShowsError } = state.ListingPage;
  const {
    currentUser,
    currentUserReactions,
    recentlyViewedListingIds,
    queryRecentlyViewedListingsInProgress,
    queryRecentlyViewedListingsError,
  } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    currentUserReactions,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    showListingInProgress,
    showListingError,
    reviews,
    fetchReviewsInProgress,
    fetchReviewsError,
    sendReviewInProgress,
    sendReviewError,
    addReviewLikeInProgress,
    addReviewLikeError,
    addReviewDislikeInProgress,
    addReviewDislikeError,
    similarShows: similarShowIds.map(id => getListing(id)),
    querySimilarShowsInProgress,
    querySimilarShowsError,
    recentlyViewedListings: recentlyViewedListingIds.map(id => getListing(id)),
    queryRecentlyViewedListingsInProgress,
    queryRecentlyViewedListingsError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSendReview: (listingId, review) => dispatch(sendReview(listingId, review)),
  onAddReviewLike: (listingId, reviewId) => dispatch(addReviewLike(listingId, reviewId)),
  onAddReviewDislike: (listingId, reviewId) => dispatch(addReviewDislike(listingId, reviewId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ReviewsPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ReviewsPageComponent);

export default ReviewsPage;
