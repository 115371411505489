import React from 'react';
import { findOptionsForSelectFilter } from '../../util/search';
import { ResponsiveImage, NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';

import css from './ReviewsPage.module.css';

const VerticalListingCard = props => {
  const { listing } = props;
  const { title, publicData } = listing.attributes;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const category = categoryOptions.find(c => c.key === publicData.category);

  return (
    <NamedLink
      className={css.verticalListingCard}
      name="ListingPage"
      params={{ id: listing.id.uuid, slug: createSlug(title) }}
    >
      <div className={css.verticalListingCardImageContainer}>
        <ResponsiveImage
          rootClassName={css.verticalListingCardRootImage}
          image={firstImage}
          alt={title}
          variants={['scaled-small', 'scaled-medium']}
        />
      </div>
      <div className={css.verticalListingCardContent}>
        <h3 className={css.verticalListingCardTitle}>{title}</h3>
        <div className={css.verticalListingCardSubTitle}>{category.label}</div>
      </div>
    </NamedLink>
  );
};

const SectionSimilarShows = props => {
  const { similarShows, querySimilarShowsInProgress, querySimilarShowsError } = props;

  const hasSimilarShows = similarShows.length > 0;
  const showSimilarShows =
    hasSimilarShows && !querySimilarShowsInProgress && !querySimilarShowsError;

  return showSimilarShows ? (
    <div className={css.sectionSimilarShows}>
      <h2 className={css.similarShowsHeading}>
        <FormattedMessage id="ReviewsPage.similarShows" />
      </h2>
      {similarShows.map(l => {
        return <VerticalListingCard key={l.id.uuid} listing={l} />;
      })}
    </div>
  ) : null;
};

export default SectionSimilarShows;
